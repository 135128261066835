.container {
  min-height: 100vh;
  height: 100%;
  position: relative;
  padding: 5rem 0 !important;
}

.gradient-background {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

@font-face {
  font-family: "LexendDeca";
  src: url("../fonts/LexendDeca/LexendDeca-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "LexendDeca-Bold";
  src: url("../fonts/LexendDeca/LexendDeca-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

$font-family-base: "LexendDeca", sans-serif;
$font-family-bold: "LexendDeca-Bold", sans-serif;

body {
  font-family: $font-family-base;
}

.bold {
  font-family: $font-family-bold;
}

.italics {
  font-style: italic;
}

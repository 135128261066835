.loader-spinner-wrapper {
  // background-color: rgba(16, 23, 36, 0.7);
  background-color: rgba(18, 18, 18, 0.9);
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1350;
  left: 0;
  top: 0;
  .loader-image {
    width: 100px;
    min-width: 100px;
    min-height: 100px;
  }
}

/**
* Not Found Page
*/
.not-found-page {
  .not-found-image {
    // width: 25%;
  }
  .not-found-card-wrapper {
    border-radius: 5px;
    padding: 1rem 2rem;
  }
}

@media screen and (max-width: 820px) {
  .not-found-page {
    .not-found-image {
      // width: 50%;
    }
  }
}

.swipe-to-refresh {
  position: sticky;
  top: 1rem;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 0;
  max-height: 70px;
  height: 0px;
  transition: all 200ms ease-in-out;
  margin-bottom: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  &.visible {
    opacity: 1;
    visibility: visible;
    min-height: 50px;
    max-height: 70px;
  }
}

// MAIN
@import "../assets/scss/grid.scss";

#root {
  scroll-behavior: smooth;
  .ellipsis {
    white-space: nowrap; /* Prevents text from wrapping */
    overflow: hidden; /* Hides the overflow text */
    text-overflow: ellipsis; /* Adds ellipsis at the end of the text */
  }

  .bg-image-character {
    position: fixed;
    z-index: -1;
    opacity: 0.3;
    width: 30%;
    left: auto;
    right: 0;
    top: 45vh;
    bottom: auto;
  }
}

img {
  pointer-events: none;
}

.direction-rtl {
  direction: rtl;
}

hr {
  margin: 2rem 0;
}

.text-underline {
  text-decoration: underline solid #ad932d;
}
a:not(.MuiButtonBase-root) {
  color: #0080ab;
  font-weight: 400;
  text-decoration: underline solid #ad932d !important;
  &:visited {
    color: #0080ab;
  }
}
